<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push({name: 'MyProfile/RestingHeartRate'})"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.measurement')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <p class="ion-padding-horizontal">
        {{ __('interface.measurement.header-one') }}
      </p>
      <p class="ion-padding-horizontal">
        {{ __('interface.measurement.header-two') }}
      </p>
      <div class="countdown-timer">
        <countdown-timer-pulse :pulse-text="seconds" :label-text="__('interface.seconds')"/>
      </div>
      <ion-button v-if="!isStarted" expand="full" class="ion-margin-vertical" @click="actionStart">{{ __('interface.start') }}</ion-button>
      <ion-button v-else color="danger" expand="full" class="ion-margin-vertical" @click="actionStop">{{ __('interface.stop') }}</ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {alertController, IonPage, loadingController, toastController} from '@ionic/vue';
import CountdownTimerPulse from '../../../components/CountdownTimerPulse';
import ble from 'cordova-plugin-ble-central/www/ble';
import {API} from '@/utils/apiWrapper';
import {mapActions} from 'vuex';
import { Storage } from '@capacitor/storage';

const MEASUREMENT_SECONDS = 60

export default {
  name: 'Measurement',
  components: {CountdownTimerPulse, IonPage},
  computed: {
    heartRate() {
      return this.$store.state.ble.heartRate ?? 0
    }
  },
  data() {
    return {
      isStarted: false,
      seconds: MEASUREMENT_SECONDS,
      interval: null,
      loading: null,
      measurement: []
    }
  },
  methods: {
    ...mapActions({
      startNotification: 'ble/startNotification',
      stopNotification: 'ble/stopNotification'
    }),
    actionStart() {
      this.startNotification()
      this.isStarted = true
      this.measurement = []
      this.timer = setInterval(() => {
        this.seconds -= 1
        this.measurement.push(this.heartRate)

        if(this.heartRate <= 0) {
          this.actionStop()
          this.showDeviceDisconnectedAlert()
        }

        if (this.seconds <= 0) {
          this.actionStop()
          this.calculateAvgHeartRate();
        }
      }, 1000)
    },
    actionStop() {
      this.stopNotification()
      clearTimeout(this.timer)
      this.timer = null
      this.seconds = MEASUREMENT_SECONDS
      this.isStarted = false
    },
    async saveMeasurement(avg) {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      });
      await loading.present();

      await API.post('TheSpottApi', '/me-rhr', {
        body: {
          resting_heart_rate: avg
        }
      }).then(async response => {
        this.$store.commit('setUserRhr', response.resting_heart_rate)
        await loading.dismiss();
        await this.$router.push({name: 'MyProfile/RestingHeartRate'})
        const toast = await toastController.create({
          message: this.__('message.saved-successfully'),
          duration: 2000
        })
        await toast.present();
        this.$tracking.event({
          eventName: 'record-automatically',
          category: 'rhr'
        });
      }).catch(async () => {
        await loading.dismiss();
        const toast = await toastController.create({
          message: this.__('message.something-went-wrong'),
          duration: 2000
        })
        await toast.present();
      })
    },
    async calculateAvgHeartRate() {
      const sum = this.measurement.reduce((a, b) => a + b, 0)
      const avg = Math.round(sum / this.measurement.length)

      const alert = await alertController.create({
        header: this.__('interface.measurement.finish.header'),
        message: this.__('interface.measurement.finish.message').replace('$1', `<strong>${avg}</strong>`),
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: this.__('interface.save'),
            handler: () => {
              this.saveMeasurement(avg)
            },
          },
        ],
      });
      await alert.present();
    },
    async showDeviceDisconnectedAlert() {
      const alert = await alertController.create({
        header: this.__('interface.measurement.disconnected-alert.header'),
        message: this.__('interface.measurement.disconnected-alert.message'),
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              this.$router.push({name: 'MyProfile/RestingHeartRate'})
            },
          },
          {
            text: this.__('interface.devices'),
            handler: () => {
              this.$router.push({name: 'DevicesIndex'})
            },
          },
        ],
      });
      await alert.present();
    },
    async showDeviceNotConnectedAlert() {
      const alert = await alertController.create({
        header: this.__('interface.measurement.not-connected-alert.header'),
        message: this.__('interface.measurement.not-connected-alert.message'),
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              this.$router.push({name: 'MyProfile/RestingHeartRate'})
            },
          },
          {
            text: this.__('interface.devices'),
            handler: () => {
              this.$router.push({name: 'DevicesIndex'})
            },
          },
        ],
      });
      await alert.present();
    }
  },
  async ionViewWillEnter() {
    this.parseData = []
    this.loading = await loadingController.create({
      message: this.__('interface.loading'),
    });
    await this.loading.present();
  },
  async ionViewDidEnter() {
    const myDevicesJson = await Storage.get({key: 'my-devices'});
    const myDevices = JSON.parse(myDevicesJson.value) ?? [];
    let isConnected = false;

    myDevices.forEach(device => {
      ble.isConnected(device.id, () => {
        isConnected = true
      })
    })

    await this.loading.dismiss();

    if (!isConnected) {
      await this.showDeviceNotConnectedAlert()
    }
  }
}
</script>

<style lang="scss" scoped>
.countdown-timer {
  margin: 24px 0;
}
</style>